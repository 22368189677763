.pagination {
  .pagination-item {
    border: #d8dbe0 1px solid;
    color: #f6c214;
    background-color: #fff;

    &.MuiPaginationItem-ellipsis {
      border: 0;
      &:hover {
        border-radius: 0;
        background-color: #fff;
      }
    }
  }

  .pagination-item:hover, button[aria-current="true"] {
    z-index: 3;
    color: #fff;
    background-color: #f6c214;
    border-color: #f6c214;
  }
}
