.MuiTableCell-body {
  padding: 0 !important;
}

.MuiTableRow-root {
  display: flex !important;
  height: 100% !important;
}

.MuiTable-root {
  tbody {
      tr {
        border-bottom: 1px solid #feffff;
      }
  }
}


.default-cell-class {
  height: 100% !important;
  min-height: 50px;
  justify-items: center;
  align-items: center;
  display: flex;
  padding: 10px 0;
}

.react-confirm-alert-overlay {
  z-index: 9999 !important;
}
