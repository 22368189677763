.zoom_buttons {
  align-items: center;
  background-color: #eeeeee;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  display: flex;
  justify-content: center;
  padding: 4px;
}

.react-pdf__Page__canvas {
  width: 100% !important;
  height: auto !important;
}

.pdf-area {
  height: 760px;
  overflow: hidden auto;

  //.react-pdf__Document:hover {
  //  transform: scale(1.5);
  //}
}
