// Here you can add other styles
.border-radius-t0 {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.rdw-editor-main {
  border-color: #dfdfe1 !important;
}

.font-white {
  color: #ffffff !important;
}

.cursor-pointer {
  cursor: pointer !important;
}

.border-radius-t1 {
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
}

.no-visit {
  background: $no-visit-document-yellow;
}

.c-header-nav {
  color: #fff;
}

.lines {
  &:before {
    content: "";
    border-bottom: 1px solid #8c8e91;
    width: 100%;
    height: 1px;
    position: absolute;
    left: 0;
    top: 50%;
    z-index: 1;
  }

  span {
    z-index: 2;
    position: relative;
    background: #ebedef;
    color: #8c8e91
  }
}

.c-header {
  background: $theme-yellow;
}

.c-sidebar {
  &.c-sidebar-dark {
    background: $sidebar-bg;
  }
  .c-sidebar-nav {
    background: $sidebar-logo-bg;
    color: #000;
    .c-sidebar-nav-item {
      border: #696464 1px solid;
      border-left: 0;
      border-right: 0;
    }
  }
}

.table {
  tr {
    td {
      vertical-align: middle !important;
    }
  }
}

.opacity-25 {
  opacity: 25% !important;
}

.opacity-50 {
  opacity: 50% !important;
}

.opacity-75 {
  opacity: 75% !important;
}


.height-40rem {
  height: 40rem;
}

.break_line {
  overflow-wrap: break-word;
  word-wrap: break-word;

  -ms-word-break: break-word;
  word-break: break-word;
}

.custom-image-document {
  width: 100%;
}

.pg-viewer-wrapper {
  //img {
  //  width: 100% !important;
  //  height: auto !important;
  //}
//
  canvas {
    //width: 70vw; // The width of this element is 100% of the viewports width
    height: auto;
  }
}

.pg-viewer-wrapper {
  overflow-y: unset !important;
}

.pg-viewer {
  width: 100% !important;
}

.photo-viewer-container {
  width: 100% !important;
  height: unset !important;
}

.photo-viewer-container > img {
  width: unset !important;
  height: unset !important;
}

.MuiPaper-rounded {
  box-shadow: none !important;
}
