// Variable overrides
$theme-colors: (
  "primary":    #009CDE,
  "secondary":  #ffffff,
  "yellow": #009CDE
);

$sidebar-color: #ffffff;
$sidebar-bg: #63666A;
$sidebar-logo-bg: #63666A;
$theme-yellow: #009CDE;

$no-visit-document-yellow: #3f9c3545;
